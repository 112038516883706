<template>
  <el-form
    id="partner-details-section"
    ref="form"
    :model="form"
    @submit.prevent.native="() => {}"
  >
    <el-alert
      type="info"
      :closable="false"
      show-icon>
      <span v-html="$t('partners_management.add_partner_page.details.alert_text')"></span>
    </el-alert>
    <base-section
      :title="$t('partners_management.add_partner_page.details.title')"
      :key="key"
    >
      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            :label="$t('general.input_label.name')"
            ref="name"
            prop="name"
            :required="true"
            :error="error"
            v-model.trim="form.name"
            :validations="$v.form.name"
            name="name"
            @keyupEnter="$emit('submit')"
          ></base-input>
        </el-col>
        <el-col :span="12">
          <base-input
            :key="extIdKey"
            :label="$t('partners_management.add_partner_page.details.input_label.ext_id')"
            ref="extId"
            prop="extId"
            v-model="form.extId"
            :validations="$v.form.extId"
            :disabled="form.extIdEqualToName"
            v-mask="extIdMask"
            name="extId"
            @keyupEnter="$emit('submit')"
          ></base-input>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :offset="12" :span="12">
          <base-checkbox
            :label="$t('partners_management.add_partner_page.details.checkbox_label.ext_id')"
            v-model="form.extIdEqualToName"
          ></base-checkbox>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-translations
            v-model="form.nameLabel.labelTranslations"
          ></base-translations>
        </el-col>
        <el-col :span="12">
          <base-select
            :label="$t('partners_management.add_partner_page.details.label.partner_type')"
            ref="partnerType"
            prop="partnerType"
            :required="true"
            :items="partnerTypesList"
            v-model="form.partnerType"
            :validations="$v.form.partnerType"
            name="partnerType"
          />
        </el-col>
      </el-row>

    </base-section>
  </el-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'PartnerDetails',
  props: {
    value: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    const mask = []
    for (let i = 1; i < 101; i++) {
      mask.push('#'.repeat(i))
    }
    return {
      key: '',
      form: cloneDeep(this.value),
      error: '',
      extIdKey: '',
      extIdMask: {
        mask: mask,
        tokens: {
          '#': { pattern: /[a-zA-Z0-9\s_-]/, transform: v => v.replaceAll(/\s/igu, '_') }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      partnerTypes: 'partner/partnerTypes'
    }),
    partnerTypesList () {
      return this.partnerTypes.map(value => {
        return {
          label: this.$t('partners_management.add_partner_page.partner_type_labels.' + value),
          value
        }
      })
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      partnerType: {
        required
      }
    }
  },
  methods: {
    addNameTranslations () {
      console.log('addNameTranslations')
    }
  },
  watch: {
    form: {
      deep: true,
      handler (v) {
        this.$emit('input', v)
      }
    },
    'form.name' (v) {
      if (this.form.extIdEqualToName) {
        this.form.extId = v.replaceAll(' ', '_')
        this.extIdKey = this.$key()
      }
    },
    'form.extIdEqualToName' (v) {
      if (v) {
        this.form.extId = this.form.name.replaceAll(' ', '_')
        this.extIdKey = this.$key()
      } else {
        this.form.extId = ''
        this.extIdKey = this.$key()
      }
    }
  }
}
</script>

<style scoped>

</style>
