<template>
  <el-form
    ref="form"
    :model="model"
    @submit.prevent.native="null"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    label-position="top"
    v-loading="loading"
  >
    <!--    The colour manual can be found here.-->
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('partners_management.view_partner.linked.alert')" />
    </el-alert>

    <linked-partner-section
      :title="$t('partners_management.view_partner.linked.subpartners.title')"
      :subtitle="$t('partners_management.view_partner.linked.subpartners.subtitle', { mspName: this.mspName })"
      :empty-subtitle="$t('partners_management.view_partner.linked.subpartners.empty_subtitle', { mspName: this.mspName })"
      :partners="subPartnerOspIds"
      :partnerOspId="partnerOspId"
      :type="'sub'"
    ></linked-partner-section>

    <linked-partner-section
      :title="$t('partners_management.view_partner.linked.superpartners.title')"
      :subtitle="$t('partners_management.view_partner.linked.superpartners.subtitle', { mspName: this.mspName })"
      :empty-subtitle="$t('partners_management.view_partner.linked.superpartners.empty_subtitle', { mspName: this.mspName })"
      :partners="superPartnerOspIds"
      :partnerOspId="partnerOspId"
      :type="'sup'"
    ></linked-partner-section>
  </el-form>
</template>
<script>
import { sortBy } from 'lodash'
import ViewPartner from '@/mixins/ViewPartner'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store'
import LinkedPartnerSection from '@/components/Partner/LinkedPartnerSection'

export default {
  name: 'PartnerViewLinkedPartners',
  mixins: [ConfirmBeforeRouterLeave, ViewPartner],
  components: {
    LinkedPartnerSection
  },
  data () {
    return {
      model: null,
      key: '',
      loading: false,
      subPartnerOspIds: [],
      superPartnerOspIds: [],
      saving: false,
      saveDisabled: true,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount',
      allPartners: 'partner/allPartners'
    }),
    mspName () {
      return this.$trans(this.partner.partner.data.nameLabel)
    }
  },
  methods: {
    ...mapActions({
      fetchByOspId: 'partner/fetchByOspId'
    }),
    fetchData () {
      return this.$api.partner.getRelated(
        {
          partnerOspId: this.partner.partner.ospId
        },
        {
          onSuccess: ({ subPartnerOspIds, superPartnerOspIds }) => {
            this.subPartnerOspIds = this.preparePartners(subPartnerOspIds)
            this.superPartnerOspIds = this.preparePartners(superPartnerOspIds)
          }
        }
      )
    },
    preparePartners (partners) {
      return sortBy(
        this.allPartners.filter(({ ospId }) => {
          return partners.includes(ospId)
        }),
        v => {
          try {
            return v.name.toLowerCase()
          } catch (e) {
            return v?.name || ''
          }
        }
      )
    },
    handleAddLinkedPartner () {
      this.$router.push({
        name: 'AddLinkedPartners',
        params: this.$route.params
      })
    }
  },
  async mounted () {
    this.loading = true
    await this.fetchData()
    this.loading = false
    this.$root.$on('updateLinkedPartners', async () => {
      await this.fetchData()
    })
  },
  async beforeRouteEnter (to, from, next) {
    if (!store.getters['partner/allPartners'].length) {
      await store.dispatch('partner/fetchAllActivePartners')
    }
    next()
  }
}
</script>
