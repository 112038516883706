import { get } from 'lodash'
import store from '@/store'
import { mapGetters, mapActions } from 'vuex'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'

export default {
  name: 'ViewPartner',
  mixins: [ConfirmBeforeRouterLeave],
  props: ['partnerOspId'],
  data () {
    return {
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false,
      applyChangesToCorporateAccounts: true
    }
  },
  computed: {
    ...mapGetters({
      $_getByOspId: 'partner/getByOspId'
    }),
    partner () {
      return this.partnerOspId && this.$_getByOspId(this.partnerOspId)
    }
  },
  watch: {
    applyChangesToCorporateAccounts: function () {
      this.dirty = true
    }
  },
  methods: {
    ...mapActions({
      fetchByOspId: 'partner/fetchByOspId'
    }),
    async goBack () {
      if (await this.allowRouterLeave()) {
        return this.$router.push({ name: 'PartnersViewAll' })
      }
    },
    handleSaveChanges () {
      this.saving = true
      this.model.update({
        successNotification: this.$t('partners_management.view_partner.details.success_notification'),
        onSuccess: () => {
          this.fetchByOspId(this.partnerOspId)
        },
        onFinally: () => {
          this.saving = false
          this.dirty = false
        }
      }, this.applyChangesToCorporateAccounts)
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partnerOspId = get(to.params, 'partnerOspId', '')
    if (partnerOspId && !store.getters['partner/getByOspId'](partnerOspId)) {
      try {
        await store.dispatch('partner/fetchByOspId', partnerOspId)
      } catch (e) {
        console.error(e)
        return next(from)
      }
    }
    next()
  }
}
