<template>
  <el-form ref="form" :model="model" @submit.prevent.native="null" :key="key" autocomplete="off" id="appUserForm"
           label-position="top"
           v-loading="loading">
    <base-section
      :title="$t('user_management.section.budgets.title')"
    >
      <el-row ref="budgets" :key="key" v-loading="loading">
        <template v-for="(type, index) in budgetTypes">
          <el-col :span="24" :key="index + 'checkbox'">
            <base-checkbox
              :label="type.name"
              :value="selectedBudgets[type.budgetType]"
              @input="(v) => change(type.budgetType, v)"
              :explanation="type.info"
            />
          </el-col>
        </template>
      </el-row>
    </base-section>
  </el-form>
</template>

<script>

import ViewPartner from '@/mixins/ViewPartner'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapActions, mapGetters } from 'vuex'
import { keys, zipObject } from 'lodash'
import BudgetTypeRepository from '@/app/repositories/BudgetTypeRepository'

const budgetTypeRepository = new BudgetTypeRepository()

export default {
  name: 'PartnerViewBudgets',
  mixins: [ConfirmBeforeRouterLeave, ViewPartner],
  components: {},
  data () {
    return {
      model: null,
      budgetTypes: [],
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount',
      selectedCorporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    partnerTypesList () {
      return this.partnerTypes.map(value => {
        return {
          label: this.$t('partners_management.add_partner_page.partner_type_labels.' + value),
          value
        }
      })
    },
    selectedBudgets: {
      get () {
        const budgetTypes = this.model.selectedBudgetTypes || []
        return zipObject(budgetTypes, new Array(budgetTypes.length).fill(true))
      }
    }
  },
  methods: {
    ...mapActions({
      fetchByOspId: 'partner/fetchByOspId',
      fetchSelectedCA: 'corporateAccount/selectCorporateAccountOspId'
    }),
    handleSaveChanges () {
      this.saving = true
      this.model.update({
        successNotification: this.$t('partners_management.view_partner.details.success_notification'),
        onSuccess: () => {
          this.fetchByOspId(this.partnerOspId)
          if (
            this.$auth.user().isOspAdmin &&
            this.selectedCorporateAccount.ospId &&
            this.selectedCorporateAccount.ospId !== this.corporateAccount.ospId
          ) {
            this.fetchSelectedCA(this.selectedCorporateAccount.ospId)
          }
        },
        onFinally: () => {
          this.saving = false
          this.dirty = false
        }
      }, false)
    },
    fetchBudgetTypes () {
      return budgetTypeRepository.getAll(this.corporateAccount.extId,
        {
          onSuccess: r => {
            this.budgetTypes = r
          }
        })
    },
    change (target, value) {
      const selectedBudgets = { ...this.selectedBudgets }
      this.$set(selectedBudgets, target, value)
      this.model.selectedBudgetTypes = keys(selectedBudgets).filter(v => selectedBudgets[v])
      this.key = this.$key()
    }
  },
  async mounted () {
    this.model = this.partner.clone()
    await this.fetchBudgetTypes()
    this.key = this.$key()
    this.$watch('model.selectedBudgetTypes', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>
