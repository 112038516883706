<template>
  <div class="--fix-height-base-transfer">
    <el-form
      ref="form"
      :model="model"
      @submit.prevent.native="null"
      :key="key"
      autocomplete="off"
      id="appUserForm"
      label-position="top"
      v-loading="loading"
    >
      <el-alert
        type="info"
        :closable="false"
        show-icon
      >{{ $t('partners_management.view_partner.app_config.alert') }}</el-alert>

      <base-section
        :title="$t('partners_management.add_partner_page.products.serveice_resources_section.title')"
        :is-flex="true"
        v-loading="loading"
      >
        <base-checkbox
          :label="$t('partners_management.view_partner.checkbox_aply_changes')"
          v-model="applyChangesToCorporateAccounts"
        ></base-checkbox>
        <base-transfer
          :top-titles="[$t('partners_management.add_partner_page.products.transfer.left_panel_title'), $t('partners_management.add_partner_page.products.transfer.right_panel_title')]"
          filterable
          :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
          v-model="model.selectedServiceResourcesList"
          :full-width="true"
          :height="470"
          :data="serviceResources"
        ></base-transfer>
      </base-section>
    </el-form>
  </div>
</template>

<script>
import ViewPartner from '@/mixins/ViewPartner'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { sortBy } from 'lodash'

export default {
  name: 'PartnerViewAppMenuConfig',
  mixins: [ConfirmBeforeRouterLeave, ViewPartner],
  components: {},
  data () {
    return {
      model: {
        selectedServiceResourcesList: []
      },
      serviceResources: []
    }
  },
  methods: {
    fetchServiceResources () {
      return this.$api.product.findAllServiceResources({
        onSuccess: (r) => {
          this.serviceResources = sortBy(
            r.map((p) => {
              return {
                key: p.ospId,
                label: this.$trans(p.data.nameLabel)
              }
            }),
            (p) => p.label
          )
        }
      })
    }
  },
  async mounted () {
    this.loading = true
    await this.fetchServiceResources()
    this.loading = false
    this.model = this.partner.clone()
    this.key = this.$key()
    this.$watch('model', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>
