<template>
  <page-layout
    id="add-partners-page"
    :title="$t('partners_management.add_partner_page.title')"
    :sub-title="subtitle"
    :has-back-button="true"
    :emit-back="true"
    v-loading="loading"
    @back="back"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :loading="saving"
        :label="buttonLabel"
        @click="next"
      ></base-button>
    </template>
    <partner-details
      v-if="page === 0"
      v-model="form.partnerData"
      ref="partnerDetails"
    ></partner-details>
    <partner-budgets
      v-if="page === 1"
      v-model="form.allowedBudgetTypes"
      ref="partnerBudgets"
      :budget-types="budgetTypes"
    ></partner-budgets>
    <partner-products
      v-if="page === 2"
      v-model="form"
      ref="partnerProducts"
      :products="products"
      :service-resources="serviceResources"
    ></partner-products>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import { mapGetters } from 'vuex'
import PartnerDetails from '@/components/AddPartner/PartnerDetails'
import PartnerBudgets from '@/components/AddPartner/PartnerBudgets'
import PartnerProducts from '@/components/AddPartner/PartnerProducts'
import { sortBy } from 'lodash'
import BudgetTypeRepository from '@/app/repositories/BudgetTypeRepository'

const budgetTypeRepository = new BudgetTypeRepository()

export default {
  name: 'PartnersAdd',
  components: {
    PageLayout,
    PartnerDetails,
    PartnerBudgets,
    PartnerProducts
  },
  data () {
    return {
      saving: false,
      loading: false,
      page: 0,
      pages: 2,
      budgetTypes: [],
      products: [],
      serviceResources: [],
      form: {
        partnerData: {
          extId: null,
          name: null,
          partnerType: null,
          nameLabel: {
            defaultValue: null,
            labelTranslations: []
          },
          extIdEqualToName: true
        },
        allowedBudgetTypes: ['PRIVATE_BUDGET', 'MEMBER_BUDGET'],
        allowedServiceResources: [],
        allowedProducts: []
      }
    }
  },
  watch: {
    'form.partnerData.name' (name) {
      this.form.partnerData.nameLabel.defaultValue = name
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount'
    }),
    subtitle () {
      if (this.page === 0) {
        return this.$t('partners_management.add_partner_page.subtitle_details')
      }
      if (this.page === 1) {
        return this.$t('partners_management.add_partner_page.subtitle_set_budgets')
      }
      if (this.page === 2) {
        return this.$t('partners_management.add_partner_page.subtitle_set_products')
      }
      return ''
    },
    buttonLabel () {
      if (this.page === 0) {
        return this.$t('partners_management.add_partner_page.button_label_on_details')
      }
      if (this.page === 1) {
        return this.$t('partners_management.add_partner_page.button_label_on_budgets')
      }
      if (this.page === 2) {
        return this.$t('partners_management.add_partner_page.button_label_on_products')
      }
      return ''
    }
  },
  methods: {
    back () {
      if (this.page) {
        this.page--
      } else {
        this.$router.back()
      }
    },
    next () {
      if (!this.validate()) {
        return false
      }
      if (this.page < this.pages) {
        this.page++
      } else {
        this.submit()
      }
    },
    validate () {
      if (this.page === 0) {
        const ref = this.$refs.partnerDetails
        if (ref) {
          ref.$v.form.$touch()
          if (ref.$v.form.$invalid) {
            return false
          }
        }
      }
      return true
    },
    submit () {
      this.saving = true
      this.$api.partner.create(this.form, {
        successNotification: this.$t('partners_management.add_partner_page.success_notification'),
        onSuccess: ({ ospId }) => {
          this.$router.push({
            name: 'PartnerViewDetails',
            params: {
              partnerOspId: ospId
            }
          })
        },
        onFinally: () => {
          this.saving = false
        }
      })
    },
    fetchBudgetTypes () {
      return budgetTypeRepository.getAll(this.corporateAccount.extId,
        {
          onSuccess: res => {
            this.budgetTypes = res
          }
        })
    },
    fetchProducts () {
      return this.$api.product.findAll({
        onSuccess: r => {
          this.products = sortBy(r.map(p => {
            return {
              key: p.ospId,
              label: this.$trans(p.productLabel)
            }
          }), p => p.label)
          this.form.allowedProducts = r.map(p => p.ospId)
          // this.key = this.$
        }
      })
    },
    fetchServiceResources () {
      return this.$api.product.findAllServiceResources({
        onSuccess: r => {
          this.serviceResources = sortBy(r.map(p => {
            return {
              key: p.ospId,
              label: this.$trans(p.data.nameLabel)
            }
          }), p => p.label)
          this.form.allowedServiceResources = r.map(res => res.ospId)
        }
      })
    }
  },
  mounted () {
    this.loading = true
    Promise.all([
      this.fetchBudgetTypes(),
      this.fetchProducts(),
      this.fetchServiceResources()
    ]).then(() => {
      this.loading = false
    }).catch((err) => {
      console.log(err)
      this.loading = false
    })
  }
}
</script>
