<template>
<div class='--fix-height-base-transfer'>
  <el-form
    ref="form"
    :model="model"
    @submit.prevent.native="null"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    label-position="top"
    v-loading="loading"
  >
    <el-alert
      type="info"
      :closable="false"
      show-icon
    >{{ $t('partners_management.view_partner.available_products.alert') }}</el-alert>
    <base-section :title="$t('products_management.title')" :is-flex="true" v-loading="loading">
      <base-checkbox
        :label="$t('partners_management.view_partner.checkbox_aply_changes')"
        v-model="applyChangesToCorporateAccounts"
      ></base-checkbox>
      <base-transfer
        :top-titles="[$t('partners_management.add_partner_page.products.transfer.left_panel_title'), $t('partners_management.add_partner_page.products.transfer.right_panel_title')]"
        filterable
        :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
        v-model="model.selectedProductsList"
        :height="470"
        :full-width="true"
        :data="products"
      ></base-transfer>
    </base-section>
  </el-form>
  </div>
</template>

<script>
import ViewPartner from '@/mixins/ViewPartner'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { sortBy } from 'lodash'

export default {
  name: 'PartnerViewProducts',
  mixins: [ConfirmBeforeRouterLeave, ViewPartner],
  components: {},
  data () {
    return {
      model: {
        selectedProductsList: []
      },
      products: []
    }
  },
  methods: {
    fetchProducts () {
      return this.$api.product.findAll({
        onSuccess: (r) => {
          this.products = sortBy(
            r.map((p) => {
              return {
                key: p.ospId,
                label: this.$trans(p.productLabel)
              }
            }),
            (p) => p.label
          )
          // this.key = this.$
        }
      })
    }
  },
  async mounted () {
    this.loading = true
    await this.fetchProducts()

    this.loading = false
    this.model = this.partner.clone()
    this.key = this.$key()
    this.$watch('model', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>
