export default {
  name: 'TabNavigation',
  methods: {
    back () {
      if (this.$refs.routerView && this.$refs.routerView.goBack) {
        return this.$refs.routerView.goBack()
      } else {
        this.$router.back()
      }
    },
    showButtons () {}
  },
  watch: {
    '$route.name' (name) {
      this.$nextTick().then(() => {
        this.$refs.tabs.setCurrentName(name)
      })
    },
    '$route.meta' () {
      this.$nextTick().then(() => {
        this.showButtons()
      })
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.$refs.tabs.handleTabClick = (tab) => {
        try {
          this.$router.push({
            name: tab.name,
            params: this.$route.params
          })
        } catch (e) {
          console.log(e)
        }
      }
      this.$refs.tabs.setCurrentName(this.$route.name)
      setTimeout(() => {
        this.$refs.tabs.calcPaneInstances(true)
      }, 100)
      this.showButtons()
    })
  }
}
