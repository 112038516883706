<template>
  <el-form ref="form" :model="model" @submit.prevent.native="null" :key="key" autocomplete="off" id="appUserForm"
           label-position="top"
           v-loading="loading">

    <base-section
      :title="$t('partners_management.view_partner.details.account_section.title')"
    >
      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            v-if="model"
            :label="$t('general.input_label.name')"
            v-model="model.partner.data.name"
            prop="name"
            name="name"
          ></base-input>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('general.label.status')"
            label-position="top"
          >
            <div class="el-input base-empty-input">
              <template v-if="model.partner.data.active === true">
                <div class="label"><i class="ri-user-follow-line active"></i><span>{{ $t('general.active') }}</span></div>
<!--                <div class="btn-group">-->
<!--                  <base-button :label="$t('company_settings.deactivation_page.button_request_deactivation')" size="small"-->
<!--                               type="danger" @click="$emit('deactivate')"/>-->
<!--                </div>-->
              </template>
              <template v-else-if="model.partner.data.active === false">
                <div class="label"><i class="ri-user-unfollow-line inactive"></i><span>{{
                    $t('general.not_active')
                  }}</span></div>
<!--                <div class="btn-group">-->
<!--                  <base-button :label="$t('general.button_label.activate')" type="success" size="small"-->
<!--                               @click="$emit('activate')"/>-->
<!--                </div>-->
              </template>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            :label="$t('partners_management.view_partner.details.account_section.input_label.ext_id')"
            ref="extId"
            prop="extId"
            v-model="model.partner.data.extId"
            :disabled="true"
            name="extId"
            @keyupEnter="$emit('submit')"
          ></base-input>
        </el-col>
        <el-col :span="12">
          <base-select
            :label="$t('partners_management.add_partner_page.details.label.partner_type')"
            ref="partnerType"
            prop="partnerType"
            :items="partnerTypesList"
            v-model="model.partner.data.partnerType"
            name="partnerType"
          />
        </el-col>
      </el-row>
    </base-section>

    <base-section
      :title="$t('partners_management.view_partner.details.financial_section.title')"
    >
<!--      -->
      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            :label="$t('general.input_label.iban')"
            v-model="model.partner.data.iban"
            prop="iban"
            name="iban"
          ></base-input>
        </el-col>
        <el-col :span="12">
          <base-input
            :label="$t('general.input_label.bic')"
            v-model="model.partner.data.bic"
            prop="bic"
            name="bic"
          ></base-input>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <base-input
            :label="$t('general.input_label.po_number')"
            v-model="model.partner.data.poNumber"
            prop="poNumber"
            name="poNumber"
          ></base-input>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <base-checkbox
            :label="$t('partners_management.view_partner.details.financial_section.use_billing_report_asInvoice_label')"
            v-model="model.partner.data.useBillingReportAsInvoice"
          ></base-checkbox>
          <base-checkbox
            :label="$t('partners_management.view_partner.details.financial_section.invoice_corporate_accounts_label')"
            v-model="model.partner.data.invoiceCorporateAccounts"
          ></base-checkbox>
          <base-checkbox
            :label="$t('partners_management.view_partner.details.financial_section.invoice_msp_label')"
            v-model="model.partner.data.invoiceMsp"
          ></base-checkbox>
        </el-col>
      </el-row>
    </base-section>
  </el-form>
</template>

<script>

import ViewPartner from '@/mixins/ViewPartner'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PartnerViewDetails',
  mixins: [ConfirmBeforeRouterLeave, ViewPartner],
  components: {},
  data () {
    return {
      model: {
        partner: {
          data: {}
        }
      },
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      partnerTypes: 'partner/partnerTypes'
    }),
    partnerTypesList () {
      return this.partnerTypes.map(value => {
        return {
          label: this.$t('partners_management.add_partner_page.partner_type_labels.' + value),
          value
        }
      })
    }
  },
  methods: {
    ...mapActions({
      fetchByOspId: 'partner/fetchByOspId'
    }),
    handleSaveChanges () {
      this.saving = true
      this.model.update({
        successNotification: this.$t('partners_management.view_partner.details.success_notification'),
        onSuccess: () => {
          this.fetchByOspId(this.partnerOspId)
        },
        onFinally: () => {
          this.saving = false
          this.dirty = false
        }
      })
      // this.model.save({
      //   successNotification: this.$t('user_group_management.details_page.success_notification'),
      //   onSuccess: () => {
      //     this.getByOspId(this.userGroupOspId)
      //   },
      //   onFinally: () => {
      //     this.saving = false
      //     this.dirty = false
      //   }
      // })
    }
  },
  mounted () {
    this.model = this.partner.clone()
    this.key = this.$key()
    this.$watch('model.partner.data', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>
