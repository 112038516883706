export default {
  name: 'TableLoader',
  data () {
    return {
      $_TableLoader_loader: null
    }
  },
  methods: {
    TableLoader_show (tableReference = 'table') {
      this.TableLoader_hide()
      const target = this.$_TableLoader_target(tableReference)
      if (target) {
        this.$_TableLoader_loader = this.$loading({ target })
        return this.$_TableLoader_loader
      }
    },
    TableLoader_hide () {
      this.$_TableLoader_loader && this.$_TableLoader_loader.close()
      this.$_TableLoader_loader = null
    },
    $_TableLoader_target (tableReference = 'table') {
      if (!this.$refs[tableReference]) {
        return null
      }
      const emptyContainer = this.$refs[tableReference].$el.querySelector('.el-table__empty-block')
      const bodyContainer = this.$refs[tableReference].$el.querySelector('.el-table__body')
      return emptyContainer || bodyContainer
    }
  }
}
