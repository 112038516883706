<template>
  <el-form ref="form" :model="model" @submit.prevent.native="null" :key="key" autocomplete="off" id="appUserForm"
           label-position="top"
           v-loading="loading">
    <!--    The colour manual can be found here.-->
    <el-alert
      type="info"
      :closable="false"
      show-icon
    >
      <span v-html="$t('partners_management.view_partner.branding.alert_text')"/>
    </el-alert>
    <base-section
      :title="$t('partners_management.view_partner.branding.images_section.title')"
    >
      <template v-for="(imageTypes, idx) in imageTypesList">
        <el-row :gutter="24" :key="idx" :style="{'margin-bottom': idx === (imageTypesList.length - 1) ? 0 : '32px'}">
          <template v-for="(image, index) in imageTypes">
            <el-col :span="12" :key="index + 'imagetype'">
              <base-branding-image
                v-model="image.file"
                :accept="image.accept"
                :title="image.title"
                :subtitle="$t('partners_management.view_partner.branding.images_section.subtitle_' + image.type.imageType.toLowerCase())"
                @download="downloadImage(image)"
              ></base-branding-image>
            </el-col>
          </template>
        </el-row>
      </template>
    </base-section>
    <base-section
      :title="$t('partners_management.view_partner.branding.colors_section.title')"
      :withBorder="false"
      :key="colorKey"
    >
      <base-radio
        label="0"
        :value="brandingColor"
        @input="change"
      >
        {{ $t('partners_management.view_partner.branding.colors_section.radio_label.use_default') }}
      </base-radio>
      <base-radio
        label="1"
        :value="brandingColor"
        @input="change"
      >
        {{ $t('partners_management.view_partner.branding.colors_section.radio_label.use_custom') }}
      </base-radio>
      <!-- color pickers -->
      <template v-if="brandingColor === '1'">
        <div class="color-picker-group">
          <base-color-picker
            :value="branding.mainPrimaryColor.value"
            :label="$t('partners_management.view_partner.branding.colors_section.primary_color')"
            :show-reset="branding.showResetMainPrimaryColor"
            :show-clear="false"
            @reset="branding.resetMainPrimaryColor()"
            @input="
              branding.setItem(
                branding.mainPrimaryColor.significance,
                branding.mainPrimaryColor.key,
                $event
              )
            "
          ></base-color-picker>
          <template v-for="color in branding.primaryColorVariants">
            <base-color-picker
              :key="color.key + color.significance"
              :value="color.value"
              :label="getColorVariantLabel(color.key)"
              @input="branding.setItem(color.significance, color.key, $event)"
            ></base-color-picker>
          </template>
        </div>

        <div class="color-picker-group">
          <base-color-picker
            :value="branding.mainSecondaryColor.value"
            :label="$t('partners_management.view_partner.branding.colors_section.secondary_color')"
            :show-reset="branding.showResetMainSecondaryColor"
            :show-clear="false"
            @reset="branding.resetMainSecondaryColor()"
            @input="
              branding.setItem(
                branding.mainSecondaryColor.significance,
                branding.mainSecondaryColor.key,
                $event
              )
            "
          ></base-color-picker>
          <template v-for="color in branding.secondaryColorVariants">
            <base-color-picker
              :key="color.key + color.significance"
              :value="color.value"
              :label="getColorVariantLabel(color.key)"
              @input="branding.setItem(color.significance, color.key, $event)"
            ></base-color-picker>
          </template>
        </div>
      </template>
    </base-section>
  </el-form>
</template>
<script>

import ViewPartner from '@/mixins/ViewPartner'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapActions, mapGetters } from 'vuex'
import BrandingImage from '@/app/models/BrandingImage'
import { chunk } from 'lodash'
import PartnerBranding from '@/app/models/PartnerBranding'
import Api from '@/app/services/ApiService/Api'

export default {
  name: 'PartnerViewBranding',
  mixins: [ConfirmBeforeRouterLeave, ViewPartner],
  components: {},
  data () {
    return {
      model: null,
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      imageTypes: [],
      branding: {},
      brandingColor: '0',
      colors: {
        actionBar: null,
        showFavorites: null,
        showTitle: null
      },
      colorKey: null
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount'
    }),
    imageTypesList () {
      return chunk(this.imageTypes, 2)
    }
  },
  methods: {
    ...mapActions({
      fetchByOspId: 'partner/fetchByOspId'
    }),
    handleSaveChanges () {
      this.saving = true
      const promises = []
      this.imageTypes.forEach(type => {
        promises.push(type.update())
      })
      if (this.brandingColor === '1') {
        promises.push(this.branding.addOrUpdate())
      } else {
        promises.push(this.branding.useDefault())
      }
      Promise.all(promises).then(() => {
        this.$sleep(50)
        this.saving = false
        this.dirty = false
        Api.pushMessage(this.$t('partners_management.view_partner.branding.success_notification'))
      }).catch(() => {
        this.saving = false
      })
    },
    fetchBranding () {
      return this.$api.branding.getPartnerImageTypes(this.partnerOspId, {
        onSuccess: async response => {
          this.imageTypes = response.map(type => new BrandingImage({ type, targetOspId: this.partnerOspId }))
          const promises = []
          this.imageTypes.forEach(image => {
            promises.push(image.fetchImage())
          })
          await Promise.all(promises)
          this.key = this.$key()
        }
      })
    },
    change (v) {
      this.brandingColor = v
      this.colorKey = this.$key()
    },
    changeBrandingCheckbox (key, value) {
      this.branding.setItem(key, value)
      this.colorKey = this.$key()
    },
    downloadImage (image) {
      return image.download()
    },
    fetchBrandingColors () {
      PartnerBranding.fetchPartnerBranding(this.partnerOspId, {
        onSuccess: branding => {
          this.branding = branding
          this.brandingColor = this.branding.isDefault ? '0' : '1'
          this.colorKey = this.$key()
        }
      })
    },
    getColorVariantLabel (key) {
      return this.$t('partners_management.view_partner.branding.colors_section.variant') + key.replace('p', ' ')
    }
  },
  async mounted () {
    this.loading = true
    await Promise.all([
      this.fetchBranding(),
      this.fetchBrandingColors()
    ])
    this.loading = false
    this.$watch('imageTypes', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
    this.$watch('branding', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
    this.$watch('brandingColor', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.color-picker-group {
  .base-color-picker:first-child {
    margin-bottom: 1rem;
  }
  .base-color-picker:not(:first-child) {
    margin: 0.5rem 3rem;
  }
}
.color-picker-group + .color-picker-group {
    margin-top: 1.5rem;
  }
</style>
