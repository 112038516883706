import { render, staticRenderFns } from "./LinkedPartnerSection.vue?vue&type=template&id=fb6f6982&scoped=true"
import script from "./LinkedPartnerSection.vue?vue&type=script&lang=js"
export * from "./LinkedPartnerSection.vue?vue&type=script&lang=js"
import style0 from "./LinkedPartnerSection.vue?vue&type=style&index=0&id=fb6f6982&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb6f6982",
  null
  
)

export default component.exports