import api from '@/api'
import Model from './Model'
import { trans } from '@/app/helpers'

class BrandingImage extends Model {
  #repository = null

  constructor ({ type = {}, targetOspId = null, file = null }) {
    super({ type, targetOspId, file })
  }

  get accept () {
    return this.type.imageType.includes('LOGO') || this.type.imageType.includes('ICON') ? 'image/png' : 'image/jpeg'
    // imageType.match("LOGO") || imageType.match("ICON") ? mimeType = "image/png" : mimeType = "image/jpeg";
  }

  fetchImage () {
    return api.branding.getBrandImage({ imageType: this.type.imageType, targetOspId: this.targetOspId }, {
      onSuccess: response => {
        // console.log(response)
        const extension = response.type.split('/')[1]
        const name = `${this.type.imageType}.${extension}`
        if (response && response.size) {
          const file = {
            raw: response,
            size: response.size,
            name,
            percentage: 0,
            status: 'ready',
            uid: Math.ceil(Math.random() * 10000000000000),
            url: URL.createObjectURL(response)
            // uid: 1612209754490
          }
          this.file = file
          this.originalFile = file
        } else {
          this.originalFile = null
        }
        // if ()
        // this.file.raw = response
      }
    })
  }

  update (options = {}) {
    if (this.file === this.originalFile && (!this.file || this.file.uuid === this.originalFile.uuid)) {
      console.log('skip')
      return true
    }
    if (this.file && this.file.size) {
      console.log('update')
      const form = new FormData()
      form.append('targetOspId', this.targetOspId)
      form.append('imageType', this.type.imageType)
      form.append('image', this.file.raw)
      return api.branding.uploadImage(form, {
        ...options,
        onSuccess: () => {
          this.originalFile = { ...this.file }
          options.options && options.onSuccess()
        }
      })
    } else {
      console.log('delete')
      const form = {
        targetOspId: this.targetOspId,
        imageType: this.type.imageType
      }
      return api.branding.deleteImage(form, {
        ...options,
        onSuccess: () => {
          this.originalFile = this.file
          options.options && options.onSuccess()
        }
      })
    }
  }

  download () {
    const fileURL = window.URL.createObjectURL(this.file.raw)
    const fileLink = document.createElement('a')
    fileLink.setAttribute('href', fileURL)
    fileLink.setAttribute('target', '_blank')
    fileLink.setAttribute('download', this.file.name)
    fileLink.style.display = 'none'
    document.body.appendChild(fileLink)
    fileLink.click()
    document.body.removeChild(fileLink)
  }

  get title () {
    return trans(this.type.descriptionLabel)
  }
}

export default BrandingImage
