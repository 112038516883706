import Model from './Model'
import api from '@/api'
import { isEmpty, cloneDeep, isObject, keys, isString } from 'lodash'

const MAIN_COLOR_KEY = 'p100'
const PRIMARY = 'primary'
const SECONDARY = 'secondary'

class PartnerBranding extends Model {
  constructor (data = {}) {
    super(data)
    this.isDefault = isEmpty(data)
  }

  setDefault (defaultList) {
    this.defaultColors = defaultList
    for (const [key, value] of Object.entries(this.defaultColors)) {
      if (this[key] === undefined) {
        if (isObject(value)) {
          this[key] = cloneDeep(value)
        } else {
          this[key] = value
        }
      }
    }
  }

  getItem (significance, key) {
    const value = this[significance + 'Color'][key]
    return {
      key,
      value,
      significance
    }
  }

  setItem (significance, key, value) {
    this[significance + 'Color'][key] = value
  }

  resetMainPrimaryColor () {
    this.setItem(
      PRIMARY,
      MAIN_COLOR_KEY,
      this.defaultColors.primaryColor[MAIN_COLOR_KEY]
    )
  }

  resetMainSecondaryColor () {
    this.setItem(
      SECONDARY,
      MAIN_COLOR_KEY,
      this.defaultColors.secondaryColor[MAIN_COLOR_KEY]
    )
  }

  get mainPrimaryColor () {
    return {
      key: MAIN_COLOR_KEY,
      value: this.primaryColor[MAIN_COLOR_KEY],
      significance: PRIMARY
    }
  }

  get mainSecondaryColor () {
    return {
      key: MAIN_COLOR_KEY,
      value: this.secondaryColor[MAIN_COLOR_KEY],
      significance: SECONDARY
    }
  }

  get showResetMainPrimaryColor () {
    return this.mainPrimaryColor.value !== this.defaultColors.primaryColor[MAIN_COLOR_KEY]
  }

  get showResetMainSecondaryColor () {
    return this.mainSecondaryColor.value !== this.defaultColors.secondaryColor[MAIN_COLOR_KEY]
  }

  get primaryColorVariants () {
    return [
      this.getItem(PRIMARY, 'p150'),
      this.getItem(PRIMARY, 'p80'),
      this.getItem(PRIMARY, 'p60'),
      this.getItem(PRIMARY, 'p40'),
      this.getItem(PRIMARY, 'p20')
    ]
  }

  get secondaryColorVariants () {
    return [
      this.getItem(SECONDARY, 'p150'),
      this.getItem(SECONDARY, 'p80'),
      this.getItem(SECONDARY, 'p60'),
      this.getItem(SECONDARY, 'p40'),
      this.getItem(SECONDARY, 'p20')
    ]
  }

  addOrUpdate (selectedCorporateAccountOspId, options = {}) {
    keys(this.primaryColor).forEach(key => {
      if (isString(this.primaryColor[key])) {
        this.primaryColor[key] = this.primaryColor[key].toLowerCase()
      }
    })
    keys(this.secondaryColor).forEach(key => {
      if (isString(this.secondaryColor[key])) {
        this.secondaryColor[key] = this.secondaryColor[key].toLowerCase()
      }
    })
    const form = {
      partnerOspId: this.partnerOspId,
      corporateAccountOspId: selectedCorporateAccountOspId || null,
      primaryColor: { ...this.primaryColor },
      secondaryColor: { ...this.secondaryColor }
    }
    return api.branding.addOrUpdate(form, {
      ...options,
      onSuccess: res => this.setDefault(res)
    })
  }

  useDefault (ospId = this.partnerOspId, options = {}) {
    return api.branding.deleteColors(ospId, options)
  }

  static fetchPartnerBranding (ospId, options = {}) {
    return api.branding.getPartnerBranding(ospId, {
      ...options,
      onSuccess: response => {
        const partnerBranding = new PartnerBranding(response || {})
        if (!partnerBranding.partnerOspId) {
          partnerBranding.partnerOspId = ospId.split('.')[0]
        }
        return api.branding.getDefaultBrandingColors(ospId, {
          ...options,
          onSuccess: res => {
            partnerBranding.setDefault(res)
            return options.onSuccess && options.onSuccess(partnerBranding)
          }
        })
      }
    })
  }
}

export default PartnerBranding
