<template>
  <el-form ref="form" :model="form" @submit.prevent.native="() => {}">
    <el-alert
      type="info"
      :closable="false"
      show-icon>
      <span v-html="$t('partners_management.add_partner_page.products.alert_text')"></span>
    </el-alert>
    <base-section
      :title="$t('products_management.title')"
      :is-flex="true"
      v-loading="loading"
    >
      <base-transfer
        :top-titles="[$t('partners_management.add_partner_page.products.transfer.left_panel_title'), $t('partners_management.add_partner_page.products.transfer.right_panel_title')]"
        filterable
        :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
        v-model="form.allowedProducts"
        :height="470"
        :full-width="true"
        :data="products">
      </base-transfer>
    </base-section>
    <base-section
      :title="$t('partners_management.add_partner_page.products.serveice_resources_section.title')"
      :is-flex="true"
      v-loading="loading"
    >
      <base-transfer
        :top-titles="[$t('partners_management.add_partner_page.products.transfer.left_panel_title'), $t('partners_management.add_partner_page.products.transfer.right_panel_title')]"
        filterable
        :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
        v-model="form.allowedServiceResources"
        :height="470"
        :full-width="true"
        :data="serviceResources">
      </base-transfer>
    </base-section>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PartnerProducts',
  components: {},
  props: {
    value: {
      type: Object
    },
    products: {
      type: Array,
      default: () => []
    },
    serviceResources: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      key: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount'
    }),
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>
