<template>
  <div class="linked-partners">
    <h4 class="text-secondary-title linked-partners--title" v-html="title"></h4>
    <p v-if="partners.length" class="text-muted text-small linked-partners--subtitle" v-html="subtitle"></p>
    <p v-else class="text-muted text-small linked-partners--subtitle" v-html="emptySubtitle"></p>
    <el-table
      v-if="partners.length"
      ref="table"
      :data="partners"
      :empty-text="$t('general.empty_table')"
      row-class-name="table-row"
      style="width: 100%"
    >
      <el-table-column prop="icon" width="37">
        <template>
          <i class="ri-service-line"></i>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="name"
        :label="$t('general.label.name')"
        min-width="105"
        sortable
      ></el-table-column>
      <el-table-column fixed="right" class-name="actions-cell" width="165">
        <template slot-scope="scope">
          <base-button @click.stop="showDetails(scope.row)" size="small" :label="'See details'"> </base-button>
          <base-button
            @click.stop="handleDelete(scope.row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          ></base-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { confirmDefaultOptions } from '../../app/helpers'

export default {
  name: 'LinkedPartnerSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    emptySubtitle: {
      type: String,
      default: ''
    },
    partners: {
      type: Array,
      default: () => []
    },
    partnerOspId: {
      type: String,
      default: ''
    },
    type: {
      type: String
    }
  },
  methods: {
    showDetails ({ ospId }) {
      this.$router.push({
        name: 'PartnerViewDetails',
        params: {
          partnerOspId: ospId
        }
      })
    },
    handleDelete (data) {
      this.$confirm('', this.$t('partners_management.view_partner.linked.confirm_delete.title'), confirmDefaultOptions)
        .then(() => {
          return this.deletePartner(data)
        })
        .catch(e => console.log(e))
    },
    async deletePartner ({ ospId }) {
      let form
      if (this.type === 'sup') {
        form = {
          partnerOspId: ospId,
          subPartnerOspId: this.partnerOspId
        }
      } else if (this.type === 'sub') {
        form = {
          partnerOspId: this.partnerOspId,
          subPartnerOspId: ospId
        }
      } else {
        return
      }
      const options = {
        onSuccess: () => {
          this.$root.$emit('updateLinkedPartners')
        }
      }
      try {
        await this.$api.partner.removeRelated(form, options)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.linked-partners {
  margin: 32px 0;

  &--title {
    margin: 8px 0;
  }

  &--subtitle {
    margin: 8px 0;
  }
}
</style>
