<template>

  <el-form ref="form" @submit.prevent.native="() => {}">
    <el-alert
      type="info"
      :closable="false"
      show-icon>
      <span v-html="$t('partners_management.add_partner_page.budgets.alert_text')"></span>
    </el-alert>
    <base-section
      :title="$t('user_management.section.budgets.title')"
    >
      <el-row ref="budgets" :key="key" v-loading="loading">
        <template v-for="(type, index) in budgetTypes">
          <el-col :span="24" :key="index + 'checkbox'">
            <base-checkbox
              :label="type.name"
              :value="selectedBudgets[type.budgetType]"
              @input="(v) => change(type.budgetType, v)"
              :explanation="type.info"
            />
          </el-col>
        </template>
      </el-row>
    </base-section>
  </el-form>
</template>

<script>
import { keys, zipObject } from 'lodash'

export default {
  name: 'PartnerBudgets',
  components: {},
  props: {
    value: {
      type: Array
    },
    budgetTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      key: '',
      loading: false
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    selectedBudgets: {
      get () {
        const budgetTypes = this.form || []
        return zipObject(budgetTypes, new Array(budgetTypes.length).fill(true))
      }
    }
  },
  methods: {
    change (target, value) {
      this.$set(this.selectedBudgets, target, value)
      this.form = keys(this.selectedBudgets).filter(v => this.selectedBudgets[v])
      this.key = this.$key()
    }
  }
}
</script>
