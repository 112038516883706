import Auth from '../app/services/AuthService/Auth'
import { confirmDefaultOptions } from '../app/helpers'

export default {
  name: 'ConfirmBeforeRouterLeave',
  data () {
    return {
      dirty: false
    }
  },
  methods: {
    async allowRouterLeave () {
      if (!this.dirty) {
        return true
      }
      try {
        await this.$confirm(
          this.$t('user_management.view_app_users_page.details.confirm_exit'), {
            ...confirmDefaultOptions,
            confirmButtonText: this.$t('user_management.view_app_users_page.details.confirm_exit_submit')
          }
        )
        this.dirty = false
        return true
      } catch (e) {
        return false
      }
    },
    async goBack () {
      if (await this.allowRouterLeave()) {
        return this.$router.back()
      }
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (!Auth.token()) {
      return next()
    }
    if (!await this.allowRouterLeave()) {
      next(false)
    } else {
      next()
    }
  }
}
