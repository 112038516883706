<template>
  <page-layout
    :title="$t('partners_management.add_linked_partner.title')"
    :has-back-button="true"
    class="add-linked-partners"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :label="$t('partners_management.add_linked_partner.title')"
        :loading="saving"
        :disabled="loading"
        @click="handleSaveChanges"
      />
    </template>

    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('partners_management.add_linked_partner.alert')"> </span>
    </el-alert>
    <el-form
      ref="form"
      :model="model"
      @submit.prevent.native="null"
      :key="key"
      autocomplete="off"
      id="appUserForm"
      label-position="top"
      v-loading="loading"
    >
      <base-section :title="$t('partners_management.add_linked_partner.partner_type_section.title')">
        <base-radio
          label="super"
          :value="partnerType"
          @input="change"
          :explanation="
            $t('partners_management.add_linked_partner.partner_type_section.option_super.explanation', { mspName })
          "
        >
          {{ $t('partners_management.add_linked_partner.partner_type_section.option_super.title') }}
        </base-radio>
        <base-radio
          label="sub"
          :value="partnerType"
          @input="change"
          :explanation="
            $t('partners_management.add_linked_partner.partner_type_section.option_sub.explanation', { mspName })
          "
        >
          {{ $t('partners_management.add_linked_partner.partner_type_section.option_sub.title') }}
        </base-radio>
      </base-section>

      <base-select
        v-model="partners"
        :multiple="partnerType === 'sub'"
        filterable
        :items="partnersList"
        :placeholder="$t('partners_management.add_linked_partner.select_partner_placeholder')"
        :max-width="528"
      >
        <template #prefix><i class="ri-search-line"></i></template>
        <template v-slot:option="{ items }">
          <!--          active:true-->
          <!--          extId:"8405_ACE_C1_DUTCH"-->
          <!--          mrp:false-->
          <!--          name:"8405 ACE C1 DUTCH"-->
          <!--          ospId:"8405_ACE_C1_DUTCH"-->
          <el-option
            v-for="item in items"
            :key="item.ospId"
            :label="item.name"
            :value="item.ospId"
            class="dashboard-user-selection"
          >
            <el-row :gutter="24">
              <el-col :span="1" class="icon">
                <i class="ri-user-settings-line"></i>
              </el-col>
              <el-col :span="18"
                ><b>{{ item.name }}</b></el-col
              >
              <el-col :span="3"
                >{{
                  item.mrp
                    ? $t('partners_management.all_partners_page.mrp_label')
                    : $t('partners_management.all_partners_page.msp_label')
                }}
              </el-col>
            </el-row>
          </el-option>
        </template>
      </base-select>
    </el-form>
  </page-layout>
</template>
<script>
import ViewPartner from '@/mixins/ViewPartner'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapActions, mapGetters } from 'vuex'
import PageLayout from '@/layouts/PageLayout'
import PartnerRepository from '@/app/repositories/PartnerRepository'
import Api from '@/app/services/ApiService/Api'

const partnerRepository = new PartnerRepository()

export default {
  name: 'AddLinkedPartners',
  mixins: [ViewPartner, ConfirmBeforeRouterLeave],
  components: {
    PageLayout
  },
  data () {
    return {
      model: null,
      key: '',
      saving: false,
      loading: false,
      partnerType: 'super',
      partners: [],
      linked: []
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount',
      allPartners: 'partner/allPartners'
    }),
    mspName () {
      return this.$trans(this.partner.partner.data.nameLabel)
    },
    partnersList () {
      return this.allPartners.filter(v => !this.linked.includes(v.ospId))
    }
  },
  methods: {
    ...mapActions({
      fetchAllActivePartners: 'partner/fetchAllActivePartners'
    }),
    fetchRelated () {
      return this.$api.partner.getRelated(
        {
          partnerOspId: this.partner.partner.ospId
        },
        {
          onSuccess: ({ subPartnerOspIds, superPartnerOspIds }) => {
            this.linked = subPartnerOspIds.concat(superPartnerOspIds)
          }
        }
      )
    },
    change (value) {
      this.partnerType = value
      this.key = this.$key()
    },
    handleSaveChanges () {
      const ignoredError = 'INVALID_ARGUMENT'
      const options = {
        ignoreErrors: [ignoredError],
        successNotification: this.$t('partners_management.add_linked_partner.superpartner_added_notification'),
        onSuccess: () => {
          this.linked.push(this.partners)
        },
        onError: e => {
          if (e.getCode() === ignoredError) {
            Api.pushMessage(e.getMessage(), 'error')
          }
        },
        onFinally: () => {
          this.loading = false
        }
      }
      this.loading = true
      if (this.partnerType === 'super') {
        return partnerRepository.addSuperpartner(
          { partnerOspId: this.partnerOspId, targetOspId: this.partners },
          options
        )
      } else {
        const promises = []
        this.partners.forEach(targetOspId => {
          promises.push(
            partnerRepository.addSubpartner(
              { partnerOspId: this.partnerOspId, targetOspId },
              {
                ignoreErrors: [ignoredError],
                onSuccess: () => {
                  this.linked.push(targetOspId)
                },
                onError: e => {
                  if (e.getCode() === ignoredError) {
                    Api.pushMessage(e.getMessage(), 'error')
                  }
                }
              }
            )
          )
        })
        Promise.all(promises).then(
          () => {
            Api.pushMessage(this.$t('partners_management.add_linked_partner.subpartner_added_notification'))
            this.loading = false
          },
          () => {
            this.loading = false
          }
        )
      }
    }
  },
  async mounted () {
    this.loading = true
    await Promise.all([this.fetchRelated(), this.fetchAllActivePartners()])
    this.loading = false
  }
}
</script>
