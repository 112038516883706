<template>
  <page-layout
    :title="$t('partners_management.view_partner.title')"
    :has-back-button="true"
    :emit-back="true"
    @back="back"
  >
    <template #header-actions>
      <base-button
        v-if="showSaveDetailsButton"
        :key="key + '-save'"
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="$refs.routerView.saving"
        :disabled="!$refs.routerView.dirty"
        @click="$refs.routerView.handleSaveChanges"
      />
      <base-button
        v-else-if="showAddLinkedPartnerButton"
        :key="key + '-add'"
        :label="$t('partners_management.add_linked_partner.title')"
        type="primary"
        @click="$refs.routerView.handleAddLinkedPartner"
      />
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span">
          <i v-if="tab.icon" :class="tab.icon"></i>
          {{ tab.label }}
        </span>
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView" />
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapGetters } from 'vuex'
import ViewPartner from '../mixins/ViewPartner'
import TabNavigation from '../mixins/TabNavigation'
import { get } from 'lodash'

export default {
  name: 'PartnerViewLayout',
  components: {
    PageLayout
  },
  mixins: [ViewPartner, TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: '',
      activeName: '',
      showSaveDetailsButton: false,
      showAddLinkedPartnerButton: false
    }
  },
  methods: {
    showButtons () {
      this.showSaveDetailsButton = !!this.$route.meta.showSaveDetailsButton
      this.showAddLinkedPartnerButton = !!this.$route.meta.showAddLinkedPartnerButton
    },
    back () {
      // console.log('back')
      // console.log(this.$refs.routerView && this.$refs.routerView.goBack)
      if (this.$refs.routerView && this.$refs.routerView.goBack) {
        return this.$refs.routerView.goBack()
      } else {
        this.$router.push({ name: 'PartnersViewAll' })
      }
    }
  },
  watch: {
    '$route.name' () {
      this.showButtons()
      setTimeout(() => {
        this.key = this.$key()
      }, 50)
    }
  },
  computed: {
    ...mapGetters({}),
    title () {
      return this.userGroupName
    },
    tabs () {
      const tabs = [
        {
          label: this.$t('partners_management.view_partner.tabs.details.title'),
          name: 'PartnerViewDetails',
          icon: 'ri-building-4-line'
        },
        {
          label: this.$t('partners_management.view_partner.tabs.app_menu_config.title'),
          name: 'PartnerViewAppMenuConfig',
          icon: 'ri-menu-line'
        },
        {
          label: this.$t('partners_management.view_partner.tabs.availibale_products.title'),
          name: 'PartnerViewProducts',
          icon: 'ri-ticket-line'
        },
        {
          label: this.$t('partners_management.view_partner.tabs.budgets.title'),
          name: 'PartnerViewBudgets',
          icon: 'ri-money-euro-circle-line'
        },
        {
          label: 'Branding',
          name: 'PartnerViewBranding',
          icon: 'ri-pencil-line'
        }
      ]
      if (!get(this.partner, 'partner.data.mrp', true)) {
        tabs.push({
          label: 'LinkedPartners',
          name: 'PartnerViewLinkedPartners',
          icon: 'ri-service-line'
        })
      }
      return tabs
    }
  }
}
</script>

<style lang="scss">
</style>
