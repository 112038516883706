<template>
  <page-layout
    :title="$t('partners_management.all_partners_page.title')"
    :sub-title="$t('partners_management.all_partners_page.subtitle')"
    :fixed-footer="true"
    id="partnersPage"
  >
    <template #header-actions>
      <base-button
        type="primary"
        prepend-icon="ri-add-line"
        :label="$t('partners_management.all_partners_page.button_add_partner')"
        @click="handleAddPartner"
      />
    </template>
    <div class="filters">
      <el-form ref="form" :inline="true" :model="form" @submit.prevent.native="fetchData">
        <base-input
          class="search"
          ref="search"
          prop="search"
          v-model.trim="form.search"
          name="search"
          auto-complete="off"
          :placeholder="$t('corporate_accounts_management.view_all_page.input_search')"
        >
          <template #prependInput>
            <i class="ri-search-line"></i>
          </template>
        </base-input>
        <el-form-item>
          <el-checkbox v-model="form.inactive">B
            {{ $t('partners_management.all_partners_page.input_inactive') }}
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      id="partnersTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="pagination.sort"
      row-class-name="table-row clickable"
      @row-click="handleShowDetails"
      @sort-change="sortChange"
      style="width: 100%">
      <el-table-column
        prop="icon"
        width="37">
        <template>
          <i class="ri-service-line"></i>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="name"
        :label="$t('general.label.name')"
        min-width="110"
        sortable="sortColumn"
      ></el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="mrp"
        :label="$t('general.label.type')"
        min-width="110"
        sortable="sortColumn"
      >
        <template slot-scope="{row}">
          {{
            row.mrp ? $t('partners_management.all_partners_page.mrp_label') : $t('partners_management.all_partners_page.msp_label')
          }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="$auth.isOspAdmin()"
        prop="extId"
        show-overflow-tooltip
        :label="$t('general.label.extid')"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="active"
        :label="$t('user_management.manage_app_users_page.table_header.active')"
        width="100">
        <template slot-scope="{row}">
          <i class="activity-status" :class="row.active ? 'active ri-check-line' : 'inactive ri-close-line'">
          </i>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="195">
        <template slot-scope="{row}">
          <base-button
            @click.stop="handleShowDetails(row)"
            size="small"
            :label="$t('corporate_accounts_management.view_all_page.button_see_details')"
          />
          <el-dropdown trigger="click">
            <base-button
              size="small"
              append-icon="ri-arrow-down-s-line"
              :label="$t('corporate_accounts_management.view_all_page.button_more')"
              @click.stop.prevent="() => {}"
            />
            <el-dropdown-menu
              slot="dropdown"
              class="corporateAccountsTable__row-actions-group"
            >
              <el-dropdown-item
                @click.native="handleManageBranding(row)"
              >
                <i class="ri-pencil-line"></i>{{ $t('partners_management.all_partners_page.manage_branding_option') }}
              </el-dropdown-item>
              <el-dropdown-item
                @click.native="handleManageProducts(row)"
              >
                <i class="ri-ticket-line"></i>
                {{ $t('corporate_accounts_management.view_all_page.button_manage_products') }}
              </el-dropdown-item>
              <el-dropdown-item
                @click.native="handleManageBudgets(row)"
              >
                <i
                  class="ri-money-euro-circle-line"></i>{{
                  $t('partners_management.all_partners_page.manage_budgets_option')
                }}
              </el-dropdown-item>
                           <el-dropdown-item
                            class='red-text'
                             @click.native="handleDeactivatePartner(row)"
                           >
                             <i
                               class=" ri-close-line"></i>{{ $t('partners_management.all_partners_page.manage_deactivate_option') }}
                           </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <template #footer v-if="totalPages > 1">
      <base-pagination
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import { debounce, get, orderBy } from 'lodash'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import PageLayout from '@/layouts/PageLayout'
import PartnerRepository from '@/app/repositories/PartnerRepository'
import { confirmDefaultOptions } from '@/app/helpers'

const defaultSort = { order: 'ascending', prop: 'name' }

const partnerRepository = new PartnerRepository()

export default {
  name: 'PartnersViewAll',
  components: {
    PageLayout
  },
  mixins: [TableLoader, CancelRequest],
  data () {
    return {
      form: {
        search: '',
        inactive: false
      },
      dirtyTableData: [],
      pagination: {
        page: 0,
        size: 25,
        sort: defaultSort
      },
      requestSource: null
    }
  },
  computed: {
    filter () {
      return {
        active: !this.form.inactive
      }
    },
    filteredDirtyTableData () {
      const prop = this.pagination.sort.prop
      const order = this.pagination.sort.order === 'descending' ? 'desc' : 'asc'
      return orderBy(this.dirtyTableData.filter(this.filterTable),
        v => {
          try {
            return v[prop].toLowerCase()
          } catch (e) {
            return v[prop]
          }
        },
        order)
    },
    tableData () {
      return this.filteredDirtyTableData.slice(this.pagination.size * this.pagination.page, this.pagination.size * (this.pagination.page + 1))
    },
    totalPages () {
      try {
        return Math.ceil(this.filteredDirtyTableData.length / this.pagination.size)
      } catch (e) {
        return 1
      }
    }
  },
  watch: {
    'form.inactive': debounce(function () {
      this.fetchData()
    }, 200),
    totalPages (v) {
      if (v && this.pagination.page >= v) {
        this.pagination.page = v - 1
      }
    }
  },
  methods: {
    async fetchData () {
      this.TableLoader_show()
      // console.log('fetchData')
      await partnerRepository.getAll(this.filter, {
        onSuccess: response => {
          this.dirtyTableData = response
        }
      })
      this.TableLoader_hide()
    },
    filterTable (value) {
      const extId = value.extId.toLowerCase()
      const name = value.name.toLowerCase()
      const ospId = value.ospId.toLowerCase()
      const search = get(this.form, 'search', '').toLowerCase()
      return extId.indexOf(search) > -1 || name.indexOf(search) > -1 || ospId.indexOf(search) > -1
    },
    sortChange (sort) {
      this.pagination.sort = sort
    },
    async handleShowDetails ({ ospId }) {
      // console.log('handleShowDetails')
      this.TableLoader_show()
      try {
        await this.$router.push({
          name: 'PartnerViewDetails',
          params: {
            partnerOspId: ospId
          }
        })
      } catch (e) {
        this.TableLoader_hide()
      }
    },
    handleAddPartner () {
      this.$router.push({
        name: 'PartnersAdd'
      })
      // console.log('handleAddPartner')
    },
    async handleManageBranding ({ ospId }) {
      this.TableLoader_show()
      try {
        await this.$router.push({
          name: 'PartnerViewBranding',
          params: {
            partnerOspId: ospId
          }
        })
      } catch (e) {
        this.TableLoader_hide()
      }
    },
    async handleManageProducts ({ ospId }) {
      this.TableLoader_show()
      try {
        await this.$router.push({
          name: 'PartnerViewProducts',
          params: {
            partnerOspId: ospId
          }
        })
      } catch (e) {
        this.TableLoader_hide()
      }
    },
    async handleManageBudgets ({ ospId }) {
      this.TableLoader_show()
      try {
        await this.$router.push({
          name: 'PartnerViewBudgets',
          params: {
            partnerOspId: ospId
          }
        })
      } catch (e) {
        this.TableLoader_hide()
      }
    },
    handleDeactivatePartner (row) {
      this.$confirm(this.$t('partners_management.all_partners_page.manage_deactivate_modal.text'), this.$t('partners_management.all_partners_page.manage_deactivate_modal.title'),
        {
          ...confirmDefaultOptions,
          confirmButtonText: this.$t('partners_management.all_partners_page.manage_deactivate_modal.confirm'),
          confirmButtonClass: 'base-button el-button--danger'
        }).then(() => {
        partnerRepository.deactivatePartner({ ospId: row.ospId }, {
          onSuccess: () => {
            this.fetchData()
          }
        })
      })
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>
